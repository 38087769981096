import {
  AfterViewInit,
  Component,
  DoCheck,
  EventEmitter,
  Input,
  IterableDiffer,
  IterableDiffers,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Element, Line as LineEl, SVG } from '@svgdotjs/svg.js';
import { invertColor } from 'constants/color-invert-map';
import * as _ from 'lodash';
import { DATA_LETTER_ID, Letter } from 'models/letter';
import { DATA_LINE_ID, Line } from 'models/line';
import { SharedElement } from 'models/shared-element';
import { DATA_WORD_ID, Word } from 'models/word';
import { merge, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { disassemble } from 'utils';
import { SvgEditorDirective } from '../../directives/svg-editor/svg-editor.directive';
const DEFAULT_CONFIG: PageSvgEditorConfig = {};

@Component({
  selector: 'ngx-page-svg-editor',
  templateUrl: './page-svg-editor.component.html',
  styleUrls: ['./page-svg-editor.component.scss'],
})
export class PageSvgEditorComponent implements OnInit, AfterViewInit, DoCheck {
  @Output('afterSvgInit')
  svg_init_event = new EventEmitter<boolean>();

  @Output('onLightBackgroundChange')
  light_background_change_event = new EventEmitter<string>();
  @Output('onDarkBackgroundChange')
  dark_background_change_event = new EventEmitter<string>();

  @Output('onSeparatorsChange')
  separators_change_event = new EventEmitter<
    { y_top: number; y_bottom: number }[]
  >();

  @Input('svg')
  public set plain_svg(v: string) {
    this._plain_svg = v;
    setTimeout(() => {
      this.initSVG();
    });
  }
  public get plain_svg() {
    return this._plain_svg;
  }
  _plain_svg: string;

  @Input('selectable-id') layer_id: string;

  @Input('config') set config(v: PageSvgEditorConfig) {
    this._config = Object.assign({ ...DEFAULT_CONFIG }, v);
  }

  @Input()
  public lines: Line[] = [];

  get config(): PageSvgEditorConfig {
    return this._config;
  }
  private _config: PageSvgEditorConfig;

  private lines_differ: IterableDiffer<Line>;

  @ViewChild(SvgEditorDirective, { read: SvgEditorDirective, static: false })
  svg: SvgEditorDirective;

  private $misc_selected = new Subject<Element[]>();
  private $line_selected = new Subject<Line>();
  private $word_selected = new Subject<Word>();
  private $free_paths_selected = new Subject<Element[]>();
  private $line_paths_selected = new Subject<{
    line: Line;
    elements: Element[];
  }>();

  constructor(private iterableDiffers: IterableDiffers) {
    this.lines_differ = this.iterableDiffers.find([]).create<Line>(null);
  }

  ngOnInit(): void {}

  ngDoCheck() {
    const line_changed = this.lines_differ.diff(this.lines);
    if (line_changed) {
      this.rebuildLinesAccessors();
    }
  }

  ngAfterViewInit() {}

  /**
   * @description only emits at full line elements selection.
   */
  public onLineSelected(): Observable<Line> {
    return this.$line_selected.asObservable();
  }
  public onWordSelected(): Observable<Word> {
    return this.$word_selected.asObservable();
  }
  public onFreePathsSelected(): Observable<Element[]> {
    return this.$free_paths_selected.asObservable();
  }
  /**
   * @description free paths that can be converted to line.
   */
  public onLineFreePathsSelected(): Observable<{
    line: Line;
    elements: Element[];
  }> {
    return this.$line_paths_selected.asObservable();
  }
  public onSelectionChange(): Observable<void> {
    return merge(
      this.onLineSelected(),
      this.onWordSelected(),
      this.onFreePathsSelected(),
      this.onLineFreePathsSelected(),
      this.onMiscSelected(),
    ).pipe(map(() => void 0));
  }
  public onMiscSelected(): Observable<Element[]> {
    return this.$misc_selected.asObservable();
  }

  private handleLineSelected(): boolean {
    const selected = this.svg.selected();
    const line = this.lineOf(selected);
    if (!line) return false;
    if (line.els.length !== selected.length) return false;
    this.$line_selected.next(line);
    return true;
  }
  private handleWordSelected(): boolean {
    const selected = this.svg.selected();
    const word = this.wordOf(selected);
    if (!word) return false;
    this.drawWordBoundingBox(word);
    if (word.letters().length !== selected.length) return false;
    this.$word_selected.next(word);
    return true;
  }
  private handleFreePathsSelected(): boolean {
    const selected = this.svg.selected();
    if (selected.length === 0) return false;
    const free = selected.reduce((pre, curr) => {
      const letter_id = curr.remember(DATA_LETTER_ID);
      if (!letter_id) return pre && true;
      else return false;
    }, true);
    if (!free) return false;
    this.$free_paths_selected.next(selected);
    return true;
  }
  /**
   * @description free paths that can be converted to words.
   */
  private handleLineFreePathsSelected(): boolean {
    const selected = this.svg.selected();
    const line = this.lineOf(selected);

    if (!line) return false;
    const free = selected.reduce((pre, curr) => {
      const word_id = curr.remember(DATA_WORD_ID);
      if (!word_id) return pre && true;
      else return false;
    }, true);

    if (!free) return false;
    this.$line_paths_selected.next({ line, elements: selected });
    return true;
  }
  private handleMiscSelected(): true {
    const selected = this.svg.selected();
    this.$misc_selected.next(selected);
    return true;
  }

  public selectedToLine(): Line {
    const selected = this.svg.selected();
    const line = new Line(this.lines.length.toString());
    line.addLetter(selected);
    return line;
  }
  public selectedToWord(): Word {
    const selected = this.svg.selected();
    const line = this.lineOf(selected);
    const ids = selected.map((el) => el.remember(DATA_LETTER_ID));
    const word = line.addWord(ids);
    return word;
  }

  private lineOf(els: Element[]): Line {
    if (els.length === 0) return null;
    const line_id = els.reduce((pre, curr) => {
      if (pre === -1) return curr.remember(DATA_LINE_ID);
      if (pre === null) return null;
      const id = curr.remember(DATA_LINE_ID);
      if (pre === id) return id;
    }, -1);

    if (line_id == null) return null;
    return this.lines.find(({ id }) => id == line_id);
  }
  private wordOf(els: Element[]): Word {
    if (els.length === 0) return null;
    const word_id = els.reduce((pre, curr) => {
      if (pre === -1) return curr.remember(DATA_WORD_ID);
      if (pre === null) return null;
      const id = curr.remember(DATA_WORD_ID);
      if (pre === id) return id;
    }, -1);
    if (word_id == null) return null;
    const line = this.lineOf(els);
    return line.words.find((word) => word.id == word_id);
  }
  private letterOf(el: Element): Letter {
    const line_id = el.remember(DATA_LINE_ID);
    const letter_id = el.remember(DATA_LETTER_ID);
    return this.lines
      .find((line) => line.id == line_id)
      ?.letters.find((letter) => letter.id == letter_id);
  }

  public rebuildLinesAccessors() {
    this.removeLinesAccessors();
    this.lines.forEach((line) => {
      this.addLineAccessors(line);
    });
  }
  public removeLinesAccessors() {
    this.svg
      .svg()
      .find('.' + SharedElement.Accessory)
      .forEach((el) => el.remove());
  }

  public onMakeWordOfSelected() {
    const selected = this.svg.selected();
    if (selected.length == 0) return alert('no potatos are selected.');
    const line_id = selected[0].remember(DATA_LINE_ID);
    const line = this.lines.find(({ id }) => id == line_id);
    const ids = selected.map((el) => el.remember(DATA_LETTER_ID));
    line.addWord(ids);
  }

  public onAnimateSelected() {}

  public normalize() {
    this.svg.restore();
  }
  private initSVG() {
    const inited = this.svg.init({
      svg: this.plain_svg,
      select: {
        layer: '#selectable',
      },
      background: {
        layer: '#page',
      },
      separators: {
        layer: '#separators',
      },
    });

    this.svg_init_event.emit(inited);

    if (inited) {
      this.svg.onSelection().subscribe(() => {
        this.lines.forEach((line) =>
          line.words.forEach((word) => this.removeWordBoundBox(word)),
        );

        if (this.handleFreePathsSelected()) return;
        if (this.handleWordSelected()) return;
        if (this.handleLineSelected()) return;
        if (this.handleLineFreePathsSelected()) return;
        this.handleMiscSelected();
      });
      const light_bg = this.evaluateLightBackground();
      const dark_bg = this.evaluateDarkBackground();
      this.light_background_change_event.emit(light_bg);
      this.dark_background_change_event.emit(dark_bg);

      const separators = this.evaluateSeparators();
      this.separators_change_event.emit(separators);
    }
  }

  private addLineAccessors(line: Line) {
    const cy = line.els.reduce(
      (pre, curr) => Math.max(pre, curr.cy()),
      -Infinity,
    );
    this.svg
      .svg()
      .polygon([
        [0, 0],
        [15, 10],
        [15, -10],
      ])
      .addClass(SharedElement.Accessory)
      .css('cursor', 'pointer')
      .fill('#000000')
      .center(this.svg.svg().viewbox().width - 12.5, cy)
      .click(() => {
        this.toggleLine(line);
      });
  }

  private drawWordBoundingBox(word: Word) {
    const svg = this.svg.svg();
    const { y, y2 } = word.line.bbox();

    const el = svg.findOne(`#bound-${word.id}`);
    if (el) el.remove();

    const rect = SVG()
      .rect(word.bound_right - word.bound_left, y2 - y)
      .center((word.bound_left + word.bound_right) / 2, (y + y2) / 2)
      .fill({ color: 'blue', opacity: 0.4 })
      .id(`bound-${word.id}`);

    rect.node.style.pointerEvents = 'none';

    word.onBoundChange().subscribe(() => {
      rect
        .size(word.bound_right - word.bound_left, y2 - y)
        .center((word.bound_left + word.bound_right) / 2, (y + y2) / 2);
    });

    svg.add(rect);
  }
  private removeWordBoundBox(word: Word) {
    const svg = this.svg.svg();
    const el = svg.findOne(`#bound-${word.id}`);
    if (el) el.remove();
  }

  public selected(as: 'element'): Element | null;
  public selected(as: 'elements'): Element[] | null;
  public selected(as: 'letter'): Letter | null;
  public selected(as: 'letters'): Letter[];
  public selected(as: 'words'): Word[];
  public selected(as: 'word'): Word | null;
  public selected(as: 'lines'): Line[] | null;
  public selected(as: 'line'): Line | null;
  public selected(
    as: string,
  ):
    | Element
    | Element[]
    | Letter[]
    | Letter
    | Word
    | Word[]
    | Line
    | Line[]
    | null {
    if (as === 'element') return this.selectedElement();
    if (as === 'elements') return this.selectedElements();
    if (as === 'letter') return this.selectedLetter();
    if (as === 'letters') return this.selectedLetters();
    if (as === 'word') return this.selectedWord();
    if (as === 'words') return this.selectedWords();
    if (as === 'line') return this.selectedLine();
    if (as === 'lines') return this.selectedLines();
  }

  private selectedElement(): Element | null {
    const selected = this.svg.selected();
    return selected.length === 1 ? selected[0] : null;
  }
  private selectedElements(): Element[] {
    return this.svg.selected();
  }
  private selectedLine(): Line | null {
    const els = this.svg.selected();
    return this.lineOf(els);
  }
  private selectedLines(): Line[] {
    const els = this.svg.selected();
    const els_grouped_by_line_id = _.groupBy(els, (el) =>
      el.remember(DATA_LINE_ID),
    );
    const lines: Line[] = [];
    for (const line_id in els_grouped_by_line_id) {
      if (els_grouped_by_line_id.hasOwnProperty(line_id)) {
        const line = this.lineOf(els_grouped_by_line_id[line_id]);
        if (line) lines.push(line);
      }
    }
    return lines;
  }
  private selectedWord(): Word {
    const els = this.svg.selected();
    return this.wordOf(els);
  }
  private selectedWords(): Word[] {
    const els = this.svg.selected();
    const els_grouped_by_word_id = _.groupBy(els, (el) =>
      el.remember(DATA_LINE_ID),
    );
    const words: Word[] = [];
    for (const word_id in els_grouped_by_word_id) {
      if (els_grouped_by_word_id.hasOwnProperty(word_id)) {
        const word = this.wordOf(els_grouped_by_word_id[word_id]);
        if (word) words.push(word);
      }
    }
    return words;
  }
  private selectedLetter(): Letter {
    const els = this.svg.selected();
    if (els.length != 1) return null;
    else return this.letterOf(els[0]);
  }
  private selectedLetters(): Letter[] {
    const els = this.svg.selected();
    return els.map((el) => this.letterOf(el));
  }

  public select(
    items: Element | Element[] | Letter | Letter[] | Word | Word[],
  ) {
    const els: Element[] = [];
    if (items instanceof Element) {
      els.push(items);
    } else if (items instanceof Letter) {
      els.push(items.el);
    } else if (items instanceof Word) {
      items.letters().forEach(({ el }) => els.push(el));
    } else if (Array.isArray(items)) {
      items.forEach((item) => {
        if (item instanceof Element) els.push(item);
        else if (item instanceof Letter) els.push(item.el);
        else if (item instanceof Word)
          item.letters().forEach(({ el }) => els.push(el));
      });
    }
    this.svg.select(els);
  }
  public deselect(
    items?: Element | Element[] | Letter | Letter[] | Word | Word[],
  ) {
    const els: Element[] = [];
    if (!items) return this.svg.unselect();
    else if (items instanceof Element) {
      els.push(items);
    } else if (items instanceof Letter) {
      els.push(items.el);
    } else if (items instanceof Word) {
      items.letters().forEach(({ el }) => els.push(el));
    } else if (Array.isArray(items)) {
      items.forEach((item) => {
        if (item instanceof Element) els.push(item);
        else if (item instanceof Letter) els.push(item.el);
        else if (item instanceof Word)
          item.letters().forEach(({ el }) => els.push(el));
      });
    }
    this.svg.unselect(els);
  }
  public isSelected(item: Element | Letter | Word | Line) {
    if (item instanceof Element) {
      return this.svg.isSelected(item);
    } else if (item instanceof Letter) {
      return this.svg.isSelected(item.el);
    } else if (item instanceof Word) {
      const els = [];
      item.letters().forEach((letter) => els.push(letter.el));
      return this.svg.isSelected(els);
    } else if (item instanceof Line) {
      const els = [];
      item.letters.forEach((letter) => els.push(letter.el));
      return this.svg.isSelected(els);
    }
  }

  public toggleLine(line: Line) {
    if (this.isSelected(line)) {
      this.deselect(line.letters);
    } else {
      this.deselect();
      this.select(line.letters);
    }
  }

  private evaluateLightBackground(): string {
    const clone = this.svg.backgroundEls().map((el) => el.clone());
    const viewbox = this.svg.svg().viewbox();
    const svg = SVG().viewbox(viewbox);
    clone.forEach((el) => {
      svg.add(el);
    });
    const html = svg.node.outerHTML.toString();
    return html;
  }
  private evaluateDarkBackground(): string {
    const clone = this.svg.backgroundEls().map((el) => el.clone());
    const viewbox = this.svg.svg().viewbox();
    const svg = SVG().viewbox(viewbox);
    clone.forEach((el) => {
      let fill = null;
      let stroke = null;
      const css = el.css();

      if (css.stroke && css.stroke != 'none') stroke = css.stroke;
      else stroke = el.stroke();
      if (css.fill && css.fill != 'none') fill = css.fill;
      else fill = el.fill();

      el.css('stroke', null);
      el.css('fill', null);

      if (el instanceof LineEl) {
        const { a, r, g, b } = disassemble(stroke);
        const color = `#${r}${g}${b}`;
        const alt = invertColor(`${color.toLowerCase()}`);
        el.stroke(alt || color);
      } else {
        const { a, r, g, b } = disassemble(fill);
        const color = `#${r}${g}${b}`;
        const alt = invertColor(`${color.toLowerCase()}`);
        el.fill(alt || color);
      }
      svg.add(el);
    });
    const html = svg.node.outerHTML.toString();
    return html;
  }
  private evaluateSeparators() {
    const els = this.svg.separatorEls() ?? [];
    if (els.length === 0) {
      return [];
    }

    els.sort((a, b) => a.cy() - b.cy());
    const areas: { y_top: number; y_bottom: number }[] = [];
    els.reduce((a, b) => {
      areas.push({ y_top: +a.y() + 0.5, y_bottom: +b.y() - 0.5 });
      return b;
    });
    return areas;
  }
  public svgDraft(): string {
    this.svg
      .selectables()
      .forEach((child) => child.fill(child.remember(SharedElement.BaseFill)));
    return this.svg.svg().node.outerHTML.toString();
  }
}

export interface PageSvgEditorConfig {
  non?: null;
}
