<!--! STATS -->
<section>
  <fourqan-content-card
    class="flex-1"
    [cardTitle]="'Coupons Created'"
    [noPaddingBottom]="true"
  >
    <ng-container class="card-header-item">
      <div class="d-flex align-items-center">
        <fourqan-tooltip
          [text]="'Total Coupons and All Coupons that still can be used'"
        ></fourqan-tooltip>
      </div>
    </ng-container>

    <ng-container class="card-content">
      <hr class="content-divider" />
      <fourqan-stats-card
        [displayList]="createdCoupons$ | async"
      ></fourqan-stats-card>
    </ng-container>
  </fourqan-content-card>

  <div class="d-flex flex-2 gap-1-5-rem">
    <fourqan-content-card
      class="flex-1"
      [cardTitle]="'Coupons Beneficiaries'"
      [noPaddingBottom]="true"
    >
      <ng-container class="card-header-item">
        <div class="d-flex align-items-center">
          <fourqan-tooltip
            [text]="'Number of users that are using the coupons'"
          ></fourqan-tooltip>
        </div>
      </ng-container>

      <ng-container class="card-content">
        <hr class="content-divider" />
        <fourqan-stats-card
          [displayList]="benefitedUsers$ | async"
        ></fourqan-stats-card>
      </ng-container>
    </fourqan-content-card>

    <fourqan-content-card
      class="flex-1"
      [cardTitle]="'Unused Coupons'"
      [noPaddingBottom]="true"
    >
      <ng-container class="card-header-item">
        <div class="d-flex align-items-center">
          <fourqan-tooltip
            [text]="'Number of coupons that haven\'t been used by anyone'"
          ></fourqan-tooltip>
        </div>
      </ng-container>

      <ng-container class="card-content">
        <hr class="content-divider" />
        <fourqan-stats-card
          [displayList]="unusedCoupons$ | async"
        ></fourqan-stats-card>
      </ng-container>
    </fourqan-content-card>
  </div>
</section>

<!--! CHARTS -->
<section>
  <fourqan-content-card class="flex-1" [cardTitle]="'Coupon Discounts'">
    <ng-container class="card-header-item">
      <div class="d-flex align-items-center">
        <fourqan-tooltip
          [text]="'Coupons Comparison between Claimed price and Total Price'"
        ></fourqan-tooltip>
      </div>
    </ng-container>

    <ng-container class="card-content">
      <hr class="content-divider" />

      <!--* DATES -->
      <div class="w-100 d-flex p-lr-16px gap-2rem justify-content-end">
        <!--! START -->
        <div class="input-container align-items-center d-flex">
          <span>Start Date</span>
          <fourqan-date-picker
            [dateControl]="
              this.datesGroup.controls[this.FILTER_CONTROLS.start].value
            "
            [maxDate]="this.datesGroup.controls[this.FILTER_CONTROLS.end].value"
            (dateChange)="dateChanged('start', $event)"
          ></fourqan-date-picker>
        </div>

        <!--! END -->
        <div class="input-container align-items-center d-flex">
          <span>End Date</span>
          <fourqan-date-picker
            [dateControl]="
              this.datesGroup.controls[this.FILTER_CONTROLS.end].value
            "
            [maxDate]="today"
            [minDate]="
              this.datesGroup.controls[this.FILTER_CONTROLS.start].value
            "
            (dateChange)="dateChanged('end', $event)"
          ></fourqan-date-picker>
        </div>
      </div>

      <div class="d-flex gap-0 w-100">
        <fourqan-donut-chart
          [valuesList]="[claimedValue$ | async, discountedValue$ | async]"
          [labelsList]="['Claimed Value', 'Discounted Value']"
          [height]="240"
          [legendOn]="false"
          [colorsList]="['var(--primary)', 'var(--red)']"
        ></fourqan-donut-chart>

        <div class="vertical-line"></div>

        <fourqan-compare-column-chart
          class="flex-1"
          [loading]="false"
          [height]="360"
          [compareColumnData]="this.couponsValuesList$ | async"
          [labelsList]="this.datesList$ | async"
          [legendOn]="true"
        ></fourqan-compare-column-chart>
      </div>
    </ng-container>
  </fourqan-content-card>
</section>

<section>
  <fourqan-content-card class="flex-1" [cardTitle]="'Coupon Usage'">
    <ng-container class="card-header-item">
      <div class="d-flex align-items-center">
        <fourqan-tooltip
          [text]="'Coupon purchase operations'"
        ></fourqan-tooltip>
      </div>
    </ng-container>

    <ng-container class="card-content">
      <hr class="content-divider" />

      <!--* DATES -->
      <div class="w-100 d-flex p-lr-16px gap-2rem justify-content-end">
        <!--! START -->
        <div class="input-container align-items-center d-flex">
          <span>Start Date</span>
          <fourqan-date-picker
            [dateControl]="
              this.tableDatesGroup.controls[this.FILTER_CONTROLS.start].value
            "
            [maxDate]="
              this.tableDatesGroup.controls[this.FILTER_CONTROLS.end].value
            "
            (dateChange)="tableDateChanged('start', $event)"
          ></fourqan-date-picker>
        </div>

        <!--! END -->
        <div class="input-container align-items-center d-flex">
          <span>End Date</span>
          <fourqan-date-picker
            [dateControl]="
              this.tableDatesGroup.controls[this.FILTER_CONTROLS.end].value
            "
            [maxDate]="today"
            [minDate]="
              this.tableDatesGroup.controls[this.FILTER_CONTROLS.start].value
            "
            (dateChange)="tableDateChanged('end', $event)"
          ></fourqan-date-picker>
        </div>
      </div>

      <fourqan-coupon-table
        [data]="vouchers$ | async"
        [totalCount]="totalCount | async"
        [loading]="loading | async"
        (pageEvent)="changePage($event)"
        (userEvent)="searchForName($event)"
        (titleEvent)="searchForTitle($event)"
        (codeEvent)="searchForCode($event)"
      ></fourqan-coupon-table>
    </ng-container>
  </fourqan-content-card>
</section>

<nb-card>
  <div class="header">
    <form [formGroup]="form" class="head" style="margin-inline-end: auto">
      <div class="siction">
        <h5>From</h5>
        <input
          formControlName="from"
          nbInput
          placeholder="Form Picker"
          [nbDatepicker]="formpicker"
        />
        <nb-datepicker #formpicker></nb-datepicker>
      </div>
      <div class="siction">
        <h5>To</h5>
        <input
          formControlName="to"
          nbInput
          placeholder="Form Picker"
          [nbDatepicker]="picker"
        />
        <nb-datepicker #picker></nb-datepicker>
      </div>
      <div class="siction">
        <h5>Subscription Plan</h5>
        <nb-select formControlName="slectOption">
          <nb-option
            *ngFor="let subscription of this.subscriptions.Data"
            value="{{ subscription.id }}"
            >{{ subscription.nameEn }}</nb-option
          >
        </nb-select>
      </div>
      <!-- <div class="checkbox validation-checkboxes" formGroupName="checkBox">
        <nb-checkbox formControlName="hafs" status="success">hafs</nb-checkbox>
        <nb-checkbox formControlName="warsh" status="warning"
          >Warsh</nb-checkbox
        >
        <nb-checkbox formControlName="caloun" status="danger"
          >Caloun</nb-checkbox
        >
      </div> -->
    </form>
    <nb-card-body class="buttons-row">
      <div style="display: flex;">
        <button style="margin-inline-start: auto;" nbButton [shape]="'rectangle'" (click)="generateExcel()">
          Export excel
        </button>
      </div>
    </nb-card-body>
  </div>

  <div class="chart-container">
    <ngx-chart-panel-summary
      [summary]="chartPanelSummary | async"
    ></ngx-chart-panel-summary>
    <ngx-chart-panel-header> </ngx-chart-panel-header>
    <ngx-profit-chart
      #profitChart
      [profitChartData]="profitChartData | async"
    ></ngx-profit-chart>
    <div>
      <fourqan-income-prime-table
        [data]="this.filteredDataTable | async"
        [totalCount]="(filteredDataTable | async).length ?? 0"
        [loading]="this.loadingSubs | async"
      ></fourqan-income-prime-table>
    </div>
  </div>
</nb-card>
