// import { Country } from 'models/country';
import { Statistic } from 'models/statistic';
import { StatisticDTO } from './statistic-dto';
// import { CountryDTO } from './country-dto';

export namespace StatisticsAdapter {
  export function statistic(dto: StatisticDTO.Base): Statistic {
    return new Statistic({
      createdAt: new Date(dto.createdAt),
      payed: dto.paid,
      price: dto.price,
      discount: dto.discount,
      plan: {
        id: dto.plan.id,
        name: dto.plan.nameEn ?? dto.plan.nameAr ?? dto.plan.nameFr,
        planDiscount: dto.plan.discount,
      },
      user: {
        userName: dto.user?.userName ?? dto.user.email ?? 'User Name missing',
      },
      paymentReference: dto.paymentReference ? dto.paymentReference : '',
    });
  }
  export function statistics(dtos: any): Statistic[] {
    return dtos.statistics.map((dto) => statistic(dto));
  }
}
