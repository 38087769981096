import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  OnInit,
} from '@angular/core';
import { Box, Dom, Element, G, Line, Rect, Svg, SVG } from '@svgdotjs/svg.js';
import * as _ from 'lodash';
import { isArray } from 'lodash';
import { SharedElement } from 'models/shared-element';
import { Observable, Subject } from 'rxjs';
import { SVGEditorOptions } from './svg-editor.types';

const SELECTABLE_CLASS = 'ED-SEL-ITE';
const BACKGROUND_ELEMENT_CLASS = 'BG-EL';
const SEPARATOR_ELEMENT_CLASS = 'SEP-EL';

@Directive({
  selector: '[ngxSvgEditor]',
})
export class SvgEditorDirective implements OnInit, AfterViewInit {
  constructor(private _element_ref: ElementRef<HTMLElement>) {}

  private _options: SVGEditorOptions;

  private _svg: Dom = null;

  private _is_dragging: boolean = false;

  private _joint: { x: number; y: number };

  private _start_drage: boolean = false;

  private _selectables: Element[] = [];

  private _selected: Element[] = [];

  private $selection = new Subject<void>();

  ngOnInit() {}

  ngAfterViewInit() {}

  init(options: SVGEditorOptions): boolean {
    if (this._svg) this._svg.remove();
    if (!options.select.layer && !options.select.class)
      throw new Error('a layer or a class to use must be selected');
    this._options = options;

    this._element_ref.nativeElement.insertAdjacentHTML(
      'afterbegin',
      this._options.svg,
    );

    const svg_node = this._element_ref.nativeElement
      .getElementsByTagName('svg')
      .item(0);

    if (svg_node == null) return false;
    this._svg = SVG(svg_node);

    if (this._options.select.class) {
      this.useSelectablesClass(this._options.select.class);
    } else if (this._options.select.layer) {
      this.useSelectablesLayer(this._options.select.layer);
    }

    if (this._options.background.class) {
      this.useBackgroundClass(this._options.background.class);
    } else if (this._options.background.layer) {
      this.useBackgroundLayer(this._options.background.layer);
    }

    if (this._options.separators.class) {
      this.useSeparatorsClass(this._options.separators.class);
    } else if (this._options.separators.layer) {
      this.useSeparatorsLayer(this._options.separators.layer);
    }

    this._svg.id('bar');

    this._selectables = this._svg.find('.' + SELECTABLE_CLASS);
    return true;
  }

  public backgroundEls() {
    return this.elemetsOfClass(BACKGROUND_ELEMENT_CLASS);
  }
  public separatorEls(): Line[] {
    return this.elemetsOfClass(SEPARATOR_ELEMENT_CLASS) as Line[];
  }
  public onSelection(): Observable<void> {
    return this.$selection.asObservable();
  }
  public svg(): Svg {
    return this._svg as Svg;
  }
  public selectables(): Element[] {
    return this._selectables;
  }
  public select(els: Element | Element[]) {
    if (Array.isArray(els)) {
      els.forEach((el) => this._select(el));
    } else {
      this._select(els);
    }
    this.redraw();
  }
  public unselect(els?: Element | Element[]) {
    if (!els) {
      // .concat() is added because foreach iterate over the array while
      // being spliced wich will cause only half the letters to get deselected!
      this._selected.concat().forEach((el) => this._unselect(el));
    } else if (els instanceof Element) {
      this._unselect(els);
    } else if (Array.isArray(els)) {
      els.forEach((el) => this._unselect(el));
    }
    this.redraw();
  }
  public restore(el?: Element) {
    if (el) {
      const bfill = el.remember(SharedElement.BaseFill);
      el.fill(bfill);
    } else {
      this._selectables.forEach((element) => {
        const bfill = element.remember(SharedElement.BaseFill);
        element.fill(bfill);
      });
    }
  }
  public selected(): Element[] {
    return this._selected;
  }
  public isSelected(el: Element | Element[]): boolean {
    if (el instanceof Element) {
      return this._selected.includes(el);
    } else if (Array.isArray(el)) {
      return el.reduce(
        (pre, curr) => pre && this._selected.includes(curr),
        true,
      );
    }
  }
  public isSelectable(el: Element): boolean {
    return el.hasClass(SELECTABLE_CLASS);
  }

  private useSelectablesClass(klass: string) {
    const nodes = this.elemetsOfClass(klass);
    this._selectables = _.flatten(nodes);
    this.markSelectables();
  }
  private useSelectablesLayer(layer: string) {
    const nodes = this.elemetsOfLayer(layer);
    this._selectables = nodes;
    this.markSelectables();
  }
  private useBackgroundClass(klass: string) {
    const nodes = this.elemetsOfClass(klass);
    this.markBackgroundElements(nodes);
  }
  private useBackgroundLayer(layer: string) {
    const nodes = this.elemetsOfLayer(layer);
    this.markBackgroundElements(nodes);
  }

  private useSeparatorsClass(klass: string) {
    const nodes = this.elemetsOfClass(klass);
    this.markSeparatorsElements(nodes);
  }
  private useSeparatorsLayer(layer: string) {
    const nodes = this.elemetsOfLayer(layer);
    this.markSeparatorsElements(nodes);
  }

  private markSelectables() {
    this._selectables.forEach((el) => {
      let fill = el.fill();
      if (isArray(fill)) {
        fill = fill[0];
      }
      if (el.css('fill')) {
        fill = el.css('fill');
        el.css('fill', null);
      }
      if (isArray(fill)) {
        fill = fill[0];
      }
      el.hasClass(SELECTABLE_CLASS) ? null : el.addClass(SELECTABLE_CLASS);
      el.remember(SharedElement.BaseFill, fill);
      if (fill == '#c51f1f') el.fill('#c51f1f');
      else if (fill == '#038d41') el.fill('#038d41');
      else el.fill('#000000');
    });
  }

  private markBackgroundElements(els: Element[]) {
    els.forEach((el) => {
      el.addClass(BACKGROUND_ELEMENT_CLASS);
    });
  }
  private markSeparatorsElements(els: Element[]) {
    els.forEach((el) => {
      el.addClass(SEPARATOR_ELEMENT_CLASS);
    });
  }

  private elemetsOfClass(klass: string): Element[] {
    const nodes = this._svg.find(`.${klass}`).map((node) => {
      if (node instanceof G)
        return this.ungroup(node, node.parent(typeof G) as G);
      else return node;
    });

    if (_.flatten(nodes).length === 0) {
      console.error(klass, 'Elements in svg not found !');
    }

    return _.flatten(nodes);
  }
  private elemetsOfLayer(layer_class: string): Element[] {
    const layer = this._svg.find(layer_class)[0];
    const els = [];
    layer?.children().forEach((el) => {
      if (el instanceof G) {
        const g_els = this.ungroup(el, el.parent() as Element);
        els.push(...g_els);
      } else els.push(el);
    });
    return els;
    // let nodes = layers.reduce<Element[]>(
    //   (pre, curr) => pre.concat(curr.children()),
    //   [],
    // );
    // nodes = _.flatten(
    //   nodes.map((node) => {
    //     if (node instanceof G) {
    //       const elso = this.ungroup(node, node.parent() as Element);
    //       return elso;
    //     } else return node;
    //   }),
    // );
    // return nodes;
  }

  private _select(el: Element) {
    if (this._selected.includes(el)) return;
    this._selected.push(el);
    el.addClass('selected');
  }

  @HostListener('mousedown', ['$event'])
  private onDragStart(event: MouseEvent) {
    this.rebuildSelectionBox(event.offsetX, event.offsetY);
    this._start_drage = true;
  }
  @HostListener('mousemove', ['$event'])
  private onDragMove(event: MouseEvent) {
    if (this._start_drage) {
      this._is_dragging = true;
      this.resizeSelectionBox(event.offsetX, event.offsetY);
    }
  }
  @HostListener('mouseup', ['$event'])
  private onDragEnd(event: MouseEvent) {
    const rect = this.selectionBox();
    const rect_bbox = rect.bbox();
    if (this._is_dragging) {
      if (!event.shiftKey) {
        this.unselect();
      }
      rect.remove();
      const select = this.selectables().filter((item) =>
        this.isIntersected(item.bbox(), rect_bbox),
      );
      this.select(select);
    } else {
      const el = SVG(event.target);
      if (this.isSelected(el)) this.unselect(el);
      else if (this.isSelectable(el)) this.select(el);
      rect.remove();
    }
    this._is_dragging = false;
    this._start_drage = false;
  }
  private isIntersected(el: Box, box: Box): boolean {
    const [el_vertical, el_horizontal] = [
      { s: el.y, e: el.y2 },
      { s: el.x, e: el.x2 },
    ];
    const [box_vertical, box_horizontal] = [
      { s: box.y, e: box.y2 },
      { s: box.x, e: box.x2 },
    ];

    let intersected_horizontal = false;
    let intersected_vertical = false;

    if (!(el_vertical.e < box_vertical.s || el_vertical.s > box_vertical.e))
      intersected_vertical = true;
    if (
      !(
        el_horizontal.e < box_horizontal.s || el_horizontal.s > box_horizontal.e
      )
    )
      intersected_horizontal = true;

    return intersected_horizontal && intersected_vertical;
  }
  private toRelative({ x, y }: { x: number; y: number }): {
    x: number;
    y: number;
  } {
    const { w, h } = this.viewBox();
    return {
      x: (x * w) / this._element_ref.nativeElement.offsetWidth,
      y: (y * h) / this._element_ref.nativeElement.offsetHeight,
    };
  }
  private viewBox(): { x: number; y: number; w: number; h: number } {
    const [x, y, w, h] = String(this._svg.attr('viewBox'))
      .split(' ')
      .map((v) => Number(v));
    return { x, y, w, h };
  }
  private rebuildSelectionBox(x: number, y: number) {
    const old = this._svg.findOne('.selection-box');
    if (old) old.remove();
    const rect = SVG()
      .rect(0, 0)
      .fill('#f06')
      .opacity(0.5)
      .addClass('selection-box');
    const { x: rx, y: ry } = this.toRelative({ x, y });
    this._joint = { x: rx, y: ry };
    this._svg.add(rect);
  }
  private resizeSelectionBox(x: number, y: number) {
    const rect: Rect = this._svg.findOne('.selection-box') as Rect;
    const { x: rx, y: ry } = this.toRelative({ x, y });
    const width = this._joint.x - rx;
    const height = this._joint.y - ry;
    rect.size(Math.abs(width), Math.abs(height));
    rect.center(rx + width / 2, ry + height / 2);
  }
  private selectionBox(): Rect {
    const rect: Rect = this._svg.findOne('.selection-box') as Rect;
    return rect;
  }
  private build() {
    const bboxes = this.selected().map((item) => item.bbox());
    const bbox = bboxes.reduce((pre, curr) => pre.merge(curr));
    const { w } = this.viewBox();
    const rect = SVG().rect(w, bbox.height);
    const svg = SVG().addTo('#foo').viewbox(0, 0, w, bbox.h);
    const cliped = SVG().group();
    this.selected().map((item) => {
      const clone = item.clone();
      cliped.add(clone);
    });
    cliped.center(w / 2, +cliped.height() / 2);
    svg.add(cliped);
    rect.center(w / 2, bbox.cy);
    this._svg.add(rect);
  }
  private redraw() {
    this.selectables().forEach((item) => {
      if (item.fill() == '#c51f1f' || item.fill() == '#a01616')
        item.fill('#c51f1f');
      else if (item.fill() == '#038d41' || item.fill() == '#00692f')
        item.fill('#038d41');
      else item.fill('#000000');
    });
    this._selected.forEach((item) => {
      if (item.fill() == '#c51f1f') item.fill('#a01616');
      else if (item.fill() == '#038d41') item.fill('#00692f');
      else item.fill('red');
    });
    this.$selection.next();
  }
  private ungroup(g: G, parent: Element): Element[] {
    const children = g.children();
    return _.flatten(
      children.map((child) => {
        if (child instanceof G) {
          const els = this.ungroup(child, parent);
          return els;
        } else return child;
      }),
    );
  }
  private _unselect(el: Element) {
    const index = this._selected.findIndex((_el) => el.id() == _el.id());
    if (index != -1) {
      this._selected[index].removeClass('selected');
      this._selected.splice(index, 1);
    }
  }
}
