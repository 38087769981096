import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StatisticDTO } from 'core/gateways/statistics/statistic-dto';
import { StatisticsAdapter } from 'core/gateways/statistics/statistics.adapter';
import { environment } from 'environments/environment';
import { Recital } from 'models/page';
import { Statistic } from 'models/statistic';
import {
  UserSubscription,
  UserSubscriptionDTO,
} from 'models/user-subscription';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserSubscriptionsService {
  private _admin_endpoint = `${environment.server}/user-subscription-admin`;
  private endpoint = `${environment.server}/userSubscriptions`;
  constructor(private http: HttpClient) {}

  ofUser(user_id: number): Observable<UserSubscription[]> {
    const params = new HttpParams({
      fromObject: { userId: user_id.toString() },
    });
    return this.http
      .get<UserSubscriptionDTO[]>(this.endpoint, { params })
      .pipe(map((dtos) => UserSubscription.fromDTO(dtos)));
  }

  cancelUserSubscription(user_subscription_id: number): Observable<unknown> {
    return this.http.delete(
      `${this._admin_endpoint}/${user_subscription_id}/cancel`,
    );
  }

  gift(
    userId: number,
    subscriptionPlanId: number,
    recitals: Recital[],
  ): Observable<unknown> {
    return this.http.post(`${this._admin_endpoint}/gift`, {
      userId,
      subscriptionPlanId,
      recitals,
    });
  }

  public getStatistics(): Observable<Statistic[]> {
    // const params = this.optionalDataFetch(from, to, recitals, subscriptionId);

    return this.http
      .get<StatisticDTO.Base[]>(`${this._admin_endpoint}/statistics`)
      .pipe(map((item) => StatisticsAdapter.statistics(item)));
  }

  private optionalDataFetch(
    from: Date,
    to: Date,
    recitals: string[],
    subscriptionId: number,
  ): any {
    // const date = new Date();
    // from ? from : (from = new Date(date.setMonth(date.getMonth() - 1)));
    // to ? to : (to = new Date());

    let params = new HttpParams()
      .append('from', from.toString())
      .append('to', to.toString());

    if (subscriptionId) {
      params = params.append('subscriptionId', subscriptionId);
    }

    recitals.map((recital) => {
      params = params.append('recitals', recital);
    });
    return params;
  }
}
