import { Component, Input } from '@angular/core';
import { Statistic } from 'models/statistic';

@Component({
  selector: 'fourqan-income-prime-table',
  templateUrl: './income-prime-table.component.html',
  styleUrls: ['./income-prime-table.component.scss'],
})
export class IncomePrimeTableComponent {
  @Input() totalCount: number = 200;
  @Input() data!: Array<Statistic>;
  @Input() loading: boolean = false;

  readonly COLUMNS: Array<string> = [
    'User',
    'Plan Name',
    'Start Date',
    'Price',
    'Discount',
    'Payed',
    'PaymentReference',
  ];
}
