/* eslint-disable prefer-const */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NbDateService } from '@nebular/theme';
import { OrderProfitChartSummary } from 'app/@core/data/orders-profit-chart';
import { ProfitChart } from 'app/@core/data/profit-chart';
import { ExcelService } from 'app/services/excel.service';
import { SubscriptionsService } from 'app/services/subscriptions.service';
import { UserSubscriptionsService } from 'app/services/user-subscriptions.service';
import { Entity } from 'models/entity';
import { Statistic } from 'models/statistic';
import { Subscription } from 'models/subscription';
import { User } from 'models/user';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { ProfitChartComponent } from '../../components/charts/profit-chart.component';
import { DashboardService } from './dashboard.service';
import { CompareColumnChartData } from 'types/chart-types';
import { EMPTY_PAGINATION_OBJ, PaginationDto } from 'dtos/pagination.dto';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'ngx-dashboard-charts-container',
  templateUrl: './dashboard-charts-container.component.html',
  styleUrls: ['./dashboard-charts-container.component.scss'],
})
export class DashboardChartsContainerComponent implements OnInit, OnDestroy {
  private alive = true;
  statistic: Statistic[] = [];
  form = new FormGroup({
    from: new FormControl(
      new Date(new Date().setMonth(new Date().getMonth() - 3)),
    ),
    to: new FormControl(new Date()),
    slectOption: new FormControl(),
    checkBox: new FormGroup({
      hafs: new FormControl(false),
      warsh: new FormControl(false),
      caloun: new FormControl(false),
    }),
  });

  subscriptions: Entity<Subscription[]> = new Entity([]);

  claimedValue$ = new BehaviorSubject<number>(0);
  discountedValue$ = new BehaviorSubject<number>(0);

  datesList$ = new BehaviorSubject<Array<string>>([]);
  couponsValuesList$ = new BehaviorSubject<Array<CompareColumnChartData>>([]);

  datesGroup!: FormGroup;
  today: Date = new Date();
  fourYearsAgo = new Date(new Date().setMonth(new Date().getMonth() - 48));
  readonly FILTER_CONTROLS = {
    start: 'start',
    end: 'end',
  };

  // pagination
  title!: string;
  user!: string;
  limit: number = 10;
  skip: number = 0;
  code!: string;
  tableDatesGroup!: FormGroup;

  dataTable = new BehaviorSubject<Statistic[]>([]);
  filteredDataTable = new BehaviorSubject<Statistic[]>([]);
  createdCoupons$ = new BehaviorSubject<{ value: number; text: string }[]>([
    { value: 0, text: 'Total' },
    { value: 0, text: 'Active' },
  ]);
  benefitedUsers$ = new BehaviorSubject<{ value: number; text: string }[]>([
    { value: 0, text: 'Benefited Users' },
  ]);
  unusedCoupons$ = new BehaviorSubject<{ value: number; text: string }[]>([
    { value: 0, text: 'Total' },
  ]);

  chartPanelSummary = new BehaviorSubject<OrderProfitChartSummary[]>([
    {
      title: 'Subscription Sold',
      value: 0,
    },
    {
      title: 'Value Of Subscription Sold',
      value: 0,
    },
    {
      title: 'Total Discount Value ',
      value: 0,
    },
    {
      title: 'income',
      value: 0,
    },
  ]);
  ///
  profitChartData = new BehaviorSubject<ProfitChart>({
    chartLabel: [''],
    data: [[0], [0], [0]],
  });

  vouchers$ = new BehaviorSubject<
    Array<{
      createdAt: string;
      originalCost: number;
      discountedCost: number;
      promocode: {
        title: string;
        code: string;
        endDate: string;
      };
      user: {
        userName: string;
      };
    }>
  >([]);
  private voucherPagination$ = new BehaviorSubject<PaginationDto>(
    EMPTY_PAGINATION_OBJ,
  );
  private destroy$ = new Subject<void>();
  totalCount = new BehaviorSubject<number>(10);
  loading = new BehaviorSubject<boolean>(true);
  loadingSubs = new BehaviorSubject<boolean>(true);
  private subscriptionsPagination$ = new BehaviorSubject<{
    start: Date;
    end: Date;
    subscriptionId?: number;
  }>({
    start: new Date(new Date().getMonth() - 3),
    end: new Date(),
  });

  users: User[] = [];
  @ViewChild('profitChart', { static: true }) profitChart: ProfitChartComponent;

  constructor(
    private readonly _fb: FormBuilder,
    protected dateService: NbDateService<Date>,
    private adminService: UserSubscriptionsService,
    private subscription_Service: SubscriptionsService,
    private excelService: ExcelService,
    private readonly _dashboardService: DashboardService,
  ) {
    this.datesGroup = this._fb.group({
      start: this._fb.control(this.fourYearsAgo),
      end: this._fb.control(new Date()),
    });
    this.tableDatesGroup = this._fb.group({
      start: this._fb.control(this.fourYearsAgo),
      end: this._fb.control(new Date()),
    });

    this._dashboardService.getCreatedCoupons().subscribe((res) => {
      this.createdCoupons$.next([
        { value: res.totalCount, text: 'Total' },
        { value: res.activeCount, text: 'Active' },
      ]);
    });

    this._dashboardService.getBenefitedUsers().subscribe((res) => {
      this.benefitedUsers$.next([
        { value: res.count, text: 'Benefited Users' },
      ]);
    });

    this._dashboardService.getUnusedCoupons().subscribe((res) => {
      this.unusedCoupons$.next([
        { value: res.unusedPromocodeCount, text: 'Total' },
      ]);
    });

    this._dashboardService
      .getSubscriptionPie(
        this.getDateControl('start'),
        this.getDateControl('end'),
      )
      .subscribe((res) => {
        this.claimedValue$.next(+res.totalSumWithDiscount.toFixed(0));
        this.discountedValue$.next(
          +(res.totalSum - res.totalSumWithDiscount).toFixed(0),
        );
      });

    this._dashboardService
      .getSubscriptionColumns(
        this.getDateControl('start'),
        this.getDateControl('end'),
      )
      .subscribe((res) => {
        this.datesList$.next(
          res.labels.map((date) =>
            new Date(date).toISOString().substring(0, 7),
          ),
        );
        this.couponsValuesList$.next(res.data);
      });
  }

  ngOnInit(): void {
    this.fetchingData();
    this.fetchVouchers();

    this.subscription_Service.list().subscribe((item) => {
      this.subscriptions.load(item);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.alive = false;
  }

  public dateChanged(
    changedField: 'start' | 'end',
    newDate: Date | null,
  ): void {
    this.datesGroup.controls[changedField].setValue(newDate);
    this._dashboardService
      .getSubscriptionPie(
        this.getDateControl('start'),
        this.getDateControl('end'),
      )
      .subscribe((res) => {
        this.claimedValue$.next(res.totalSumWithDiscount);
        this.discountedValue$.next(res.totalSum - res.totalSumWithDiscount);
      });

    this._dashboardService
      .getSubscriptionColumns(
        this.getDateControl('start'),
        this.getDateControl('end'),
      )
      .subscribe((res) => {
        this.datesList$.next(
          res.labels.map((date) =>
            new Date(date).toISOString().substring(0, 7),
          ),
        );
        this.couponsValuesList$.next(res.data);
      });
  }

  public tableDateChanged(
    changedField: 'start' | 'end',
    newDate: Date | null,
  ): void {
    this.tableDatesGroup.controls[changedField].setValue(newDate);
    this.updatePagination();
  }

  private getDateControl(date: 'start' | 'end'): Date {
    return this.datesGroup.controls[date].value;
  }

  private fetchVouchers(): void {
    this.voucherPagination$
      .pipe(
        switchMap((pagination: PaginationDto) => {
          return this._dashboardService
            .getPromocodeUsage(
              pagination.limit,
              pagination.skip,
              pagination.from ?? this.fourYearsAgo,
              pagination.to ?? new Date(),
              this.user,
              this.title,
              this.code,
            )
            .pipe(
              tap((voucherList) => {
                this.loading.next(false);
                this.vouchers$.next(voucherList.promocodeUsages);
                this.totalCount.next(voucherList.totalCount);
              }),
              catchError(() => {
                this.loading.next(false);
                return of();
              }),
              takeUntil(this.destroy$),
            );
        }),
      )
      .subscribe();
  }

  public changePage(event: { limit: number; skip: number }): void {
    this.limit = event.limit;
    this.skip = event.skip;
    this.updatePagination();
  }

  public searchForTitle(title: string | null): void {
    this.title = title;
    this.updatePagination();
  }

  public searchForName(name: string | null): void {
    this.user = name;
    this.updatePagination();
  }

  public searchForCode(code: string | null): void {
    this.code = code;
    this.updatePagination();
  }

  public updatePagination(): void {
    this.loading.next(true);
    const pagination: PaginationDto = {
      limit: this.limit,
      skip: this.skip,
      from: this.tableDatesGroup.controls[this.FILTER_CONTROLS.start].value,
      to: this.tableDatesGroup.controls[this.FILTER_CONTROLS.end].value,
      title: this.title,
      code: this.code,
      disabled: null,
      username: this.user,
    };
    this.voucherPagination$.next(pagination);
  }

  generateExcel(): void {
    const usersTable = this.filteredDataTable.getValue();
    this.excelService.generateExcel(usersTable, 'UsersDashboard');
  }

  private fetchingData(): void {
    this.adminService.getStatistics().subscribe((res) => {
      this.dataTable.next(res);
      this.filteredDataTable.next(res);

      this.form.valueChanges.subscribe(() => {
        this.updateSubsPagination();
      });

      this.subscriptionsPagination$
        .pipe(
          switchMap((pagination) => {
            const data: Array<Statistic> = this.dataTable.getValue();
            let result = data.filter(
              (item) =>
                item.createdAt >= pagination.start &&
                item.createdAt <= pagination.end,
            );

            if (pagination.subscriptionId) {
              result = result.filter(
                (item) => +pagination.subscriptionId === +item.plan.id,
              );
            }

            this.filteredDataTable.next(result);
            this.fillFormattingData(result);
            this.loadingSubs.next(false);

            return of();
          }),
        )
        .subscribe();

      this.updateSubsPagination();
    });
  }

  public updateSubsPagination(): void {
    this.loadingSubs.next(true);

    const form = this.form.value;

    const pagination: any = {
      start: form.from,
      end: form.to,
    };
    if (form.slectOption) {
      pagination.subscriptionId = form.slectOption;
    }

    this.subscriptionsPagination$.next(pagination);
  }

  private fillFormattingData(response: Array<Statistic>): void {
    const chartContent: {
      chartLabel: Array<string>;
      data: Array<Array<number>>;
    } = {
      chartLabel: [],
      data: this.fillArrayByDays(response),
    };

    this.profitChartData.next(chartContent);

    this.chartPanelSummary.next([
      {
        title: 'Subscriptions Sold',
        value: this.filteredDataTable.getValue().length,
      },
      {
        title: 'Value Of Subscriptions Sold',
        value: this.filteredDataTable
          .getValue()
          .reduce((sum, item) => sum + item.price, 0),
      },
      {
        title: 'Total Discount Value ',
        value: this.filteredDataTable
          .getValue()
          .reduce((sum, item) => sum + item.discount, 0),
      },
      {
        title: 'Income',
        value: this.filteredDataTable
          .getValue()
          .reduce((sum, item) => sum + item.payed, 0),
      },
    ]);
  }

  private fillArrayByDays(data: Statistic[]): Array<Array<number>> {
    const result: Array<Array<number>> = [[0], [0], [0]];

    data.map((item, index) => {
      if (index === 0) {
        result[0][index] += item.price;
        result[1][index] += item.payed;
        result[2][index] += item.discount;
      } else {
        if (this.isInTheSameDay(data[index - 1].createdAt, item.createdAt)) {
          result[0][result[0].length - 1] += item.price;
          result[1][result[1].length - 1] += item.payed;
          result[2][result[2].length - 1] += item.discount;
        } else {
          result[0].push(item.price);
          result[1].push(item.payed);
          result[2].push(item.discount);
        }
      }
    });

    console.log(result);

    return result;
  }

  private isInTheSameDay(date1: Date, date2: Date): boolean {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  }
}
