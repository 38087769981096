import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NbButtonGroupModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbDatepickerModule,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbListModule,
  NbProgressBarModule,
  NbRadioModule,
  NbSelectModule,
  NbSpinnerModule,
  NbTabsetModule,
  NbToggleModule,
} from '@nebular/theme';
import { ImagePickerModule } from 'app/shared/image-picker/image-picker.module';
import { VideoPickerModule } from 'app/shared/video-picker/video-picker.module';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxMainComponentsModule } from '../components/main.components.module';
import { AdsContainerComponent } from './ads-container/ads-container.component';
import { AddEditAdDialogComponent } from './ads-container/dialogs/add-edit-ad-dialog/add-edit-ad-dialog.component';
import { LessonsListingContainerComponent } from './lessons-listing-container/lessons-listing-container.component';
import { PageEditorContainerComponent } from './page-editor-container/page-editor-container.component';
import { PagesListingContainerComponent } from './pages-listing-container/pages-listing-container.component';
import { SubscriptionContainerComponent } from './subscription-container/subscription-container.component';
import { UserProfileContainerComponent } from './user-profile-container/user-profile-container.component';
import { UsersListingContainerComponent } from './users-listing-container/users-listing-container.component';
import { VouchersContainerComponent } from './vouchers-container/vouchers-container.component';
import { DashboardChartsContainerComponent } from './dashboard-charts-container/dashboard-charts-container.component';
import { UsersPrimeTableModule } from 'app/shared/tables/users-prime-table/users-prime-table.module';
import { DonutChartModule } from 'app/shared/charts/donut-chart/donut-chart.module';
import { ContentCardModule } from 'app/shared/content-card/content-card.module';
import { StatsCardModule } from 'app/shared/stats-card/stats-card.module';
import { CouponPrimeTableModule } from 'app/shared/tables/coupon-prime/coupon-prime-table.module';
import { DatePickerModule } from 'app/shared/date-picker/date-picker.module';
import { RadialBarModule } from 'app/shared/charts/RadialBar-chart/RadialBar-chart.module';
import { VouchersPrimeTableModule } from '../../../shared/tables/vouchers-prime-table/vouchers-prime-table.module';
import { FourqanTooltipModule } from 'app/shared/tooltip/tooltip.module';
import { CompareColumnModule } from 'app/shared/charts/compare-column/compare-column.module';
import { IncomePrimeTableModule } from 'app/shared/tables/income-prime/income-prime-table.module';
// import { NgChartsModule } from 'ng2-charts';

@NgModule({
  declarations: [
    PageEditorContainerComponent,
    UsersListingContainerComponent,
    PagesListingContainerComponent,
    UserProfileContainerComponent,
    SubscriptionContainerComponent,
    LessonsListingContainerComponent,
    VouchersContainerComponent,
    AdsContainerComponent,
    AddEditAdDialogComponent,
    DashboardChartsContainerComponent,
  ],
  imports: [
    UsersPrimeTableModule,
    IncomePrimeTableModule,
    CommonModule,
    Ng2SmartTableModule,
    NbButtonModule,
    NbIconModule,
    NbRadioModule,
    FourqanTooltipModule,
    NgxMainComponentsModule,
    NbCardModule,
    CompareColumnModule,
    NbSpinnerModule,
    NbLayoutModule,
    NbTabsetModule,
    NbProgressBarModule,
    NbListModule,
    NbToggleModule,
    ReactiveFormsModule,
    FormsModule,
    NbInputModule,
    NbSelectModule,
    NbFormFieldModule,
    ImagePickerModule,
    VideoPickerModule,
    NbButtonGroupModule,
    NbDatepickerModule.forRoot(),
    ColorPickerModule,
    NbCheckboxModule,
    DonutChartModule,
    ContentCardModule,
    StatsCardModule,
    CouponPrimeTableModule,
    DatePickerModule,
    RadialBarModule,
    VouchersPrimeTableModule,
  ],
})
export class NgxMainContainersModule {}
