import { ROLE } from 'types/roles';

export class PaginationDto {
  username?: string;
  role?: ROLE;
  email?: string;
  from?: Date;
  to?: Date;
  countryId?: number;
  skip?: number;
  limit?: number;

  title?: string;
  code?: string;
  disabled?: 0 | 1;
}

export const EMPTY_PAGINATION_OBJ: PaginationDto = {
  skip: 0,
  limit: 10,
  username: null,
  role: null,
  from: null,
  to: null,
  countryId: null,
};
