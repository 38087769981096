<p-table
  #dt
  [value]="data"
  styleClass="p-datatable-striped p-datatable-sm p-datatable-gridlines"
  currentPageReportTemplate="Showing {first} to {last}"
  [showCurrentPageReport]="true"
  dataKey="id"
  [paginator]="true"
  [scrollable]="true"
  [loading]="loading"
  [rowHover]="true"
  [rows]="10"
  scrollHeight="flex"
  responsiveLayout="scroll"
  [totalRecords]="totalCount"
>
  <ng-template pTemplate="header">
    <tr>
      <th class="bb-0" *ngFor="let column of COLUMNS" header>
        <span>{{ column }}</span>
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-sub let-rowIndex="rowIndex">
    <tr>
      <td>
        <span class="cell-width">{{ sub.user.userName }}</span>
      </td>

      <td>
        <span class="cell-width primary-text">{{ sub.plan.name }}</span>
      </td>

      <td>
        <span class="badge table-column-grey-text text-nowrap">{{
          sub.createdAt | date: 'MMM d y'
        }}</span>
      </td>

      <td>
        <span class="cell-width">{{ sub.price }}</span>
      </td>

      <td>
        <span class="cell-width">{{ sub.discount }}</span>
      </td>

      <td>
        <span class="cell-width primary-text">{{ sub.payed }}</span>
      </td>

      <td>
        <span class="my-fs-2 cell-width">
          {{ sub.paymentReference }}
        </span>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="100%">No User Subscriptions found.</td>
    </tr>
  </ng-template>
</p-table>
