import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TableModule } from 'primeng/table';
import { IncomePrimeTableComponent } from './income-prime-table.component';

@NgModule({
  declarations: [IncomePrimeTableComponent],
  imports: [TableModule, CommonModule],
  exports: [IncomePrimeTableComponent],
})
export class IncomePrimeTableModule {}
