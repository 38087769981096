import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { OrderProfitChartSummary } from 'app/@core/data/orders-profit-chart';
import { ExcelService } from 'app/services/excel.service';
import { Profile } from 'app/services/profile.service';
import { UsersService } from 'app/services/users.service';
import { CountryGateway } from 'core/gateways/country/country-gateway.service';
import { User } from 'models/user';
import { UserRoles } from 'models/user-role';
import { BehaviorSubject, of, Subject } from 'rxjs';
import {
  AddUserDialogComponent,
  AddUserDialogResult,
} from '../../dialogs/add-user-dialog/add-user-dialog.component';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';
import { EMPTY_PAGINATION_OBJ, PaginationDto } from 'dtos/pagination.dto';
import { ROLE } from 'types/roles';
import { Country } from 'models/country';

@Component({
  selector: 'fourqan-users-listing-container',
  templateUrl: './users-listing-container.component.html',
  styleUrls: ['./users-listing-container.component.scss'],
})
export class UsersListingContainerComponent implements OnInit, OnDestroy {
  chartPanelSummary = new BehaviorSubject<OrderProfitChartSummary[]>([
    {
      title: 'Total Users Count: ',
      value: 0,
    },
  ]);
  me: Partial<User> = { role: UserRoles.User };

  countries = new BehaviorSubject<Country[]>([]);
  users = new BehaviorSubject<User[]>([]);
  private userPagination$ = new BehaviorSubject<PaginationDto>(
    EMPTY_PAGINATION_OBJ,
  );
  private destroy$ = new Subject<void>();
  totalCount = new BehaviorSubject<number>(0);
  loading = new BehaviorSubject<boolean>(true);

  // pagination
  name!: string;
  email!: string;
  limit: number = 10;
  skip: number = 0;
  startDate!: Date | null;
  endDate!: Date | null;
  role!: ROLE | null;
  countryId!: number | null;

  constructor(
    private _usersService: UsersService,
    private _profile: Profile,
    private _dialogService: NbDialogService,
    private _excelService: ExcelService,
    private _countryService: CountryGateway,
  ) {}

  ngOnInit(): void {
    this._profile.Me().then((me) => (this.me = me));
    // this.form.valueChanges.subscribe((item) => {
    //   this.list(item.from, item.to);
    // });
    // this.subscription_Service.list().subscribe((item) => {
    //   this.subscriptions.load(item);
    // });
    this.getUsersList();
    this._countryService.list().subscribe((countries) => {
      this.countries.next(countries);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  downloadExcel(): void {
    this._excelService.getAllUsers();
  }

  public onEdit(event: Partial<User>): void {
    this.loading.next(true);
    this._usersService
      .update(event.id, event)
      .pipe(
        tap(() => {
          this.updatePagination();
          this.loading.next(false);
        }),
        catchError(() => {
          this.loading.next(false);
          return of();
        }),
      )
      .subscribe();
  }

  public onAddUser(): void {
    this.loading.next(true);
    this._dialogService
      .open(AddUserDialogComponent)
      .onClose.pipe(
        tap(() => this.loading.next(false)),
        catchError(() => {
          this.loading.next(false);
          return of();
        }),
      )
      .subscribe((result: AddUserDialogResult) => {
        if (result && result.added) {
          this.updatePagination();
        }
      });
  }

  get canAddUser(): boolean {
    return [UserRoles.Admin, UserRoles.SuperAdmin].includes(this.me.role);
  }

  private getUsersList(): void {
    this.userPagination$
      .pipe(
        switchMap((pagination: PaginationDto) => {
          return this._usersService.list(pagination).pipe(
            tap((usersList) => {
              this.loading.next(false);
              this.users.next(usersList.data);
              this.totalCount.next(usersList.count);
              this.chartPanelSummary.next([
                {
                  title: 'Total Users Count: ',
                  value: usersList.count,
                },
              ]);
            }),
            catchError(() => {
              this.loading.next(false);
              return of();
            }),
            takeUntil(this.destroy$),
          );
        }),
      )
      .subscribe();
  }

  public searchForString(name: string | null): void {
    this.name = name;
    this.updatePagination();
  }

  public searchForCountryId(country: Country | null): void {
    this.countryId = country?.id;
    this.updatePagination();
  }

  public searchRole(role: ROLE | null): void {
    this.role = role;
    this.updatePagination();
  }

  public searchEmail(email: string | null): void {
    this.email = email;
    this.updatePagination();
  }

  public changePage(event: { limit: number; skip: number }): void {
    this.limit = event.limit;
    this.skip = event.skip;
    this.updatePagination();
  }

  public updatePagination(): void {
    this.loading.next(true);
    const pagination: PaginationDto = {
      limit: this.limit,
      skip: this.skip,
      email: this.email,
      from: this.startDate,
      to: this.endDate,
      username: this.name,
      role: this.role,
      countryId: this.countryId,
    };
    this.userPagination$.next(pagination);
  }
}
