export class Statistic {
  createdAt: Date;
  payed: number;
  price: number;
  discount: number;
  plan: {
    id: number;
    name: string;
    planDiscount: number;
  };
  user: {
    userName: string | null;
  };
  paymentReference: string;

  constructor(spec: Statistic) {
    return spec;
  }
}
